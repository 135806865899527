<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title size="large">Secteurs</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-list>
        <ion-item
            button
            @click="$router.push({name: 'ficheSecteur', params: { id: secteur.id } })"
            v-for="secteur in secteurs"
            :key="secteur.id"
        >
          <ion-avatar slot="start" :style="{ backgroundColor: secteur.couleur}">
            {{secteur.numero}}
          </ion-avatar>
          <ion-label class="ion-text-wrap">{{ secteur.nom }}</ion-label>
        </ion-item>
      </ion-list>
      <!-- MINI PLAN
      <img class="mini-plan"
           src="@/assets/mini-plan.svg"
           alt="Mini plan du salon"
      > -->

      <!-- Créé par EMT -->
      <CreatedBy />
    </ion-content>
  </ion-page>
</template>

<script>
import CreatedBy from '@/components/CreatedBy'
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonItem, IonAvatar, IonLabel, IonList
} from '@ionic/vue'
import { mapGetters } from 'vuex'

export default {
  name: 'PageSecteurs',
  components: {
    IonPage,
    IonHeader,
    IonList,
    IonItem,
    IonAvatar,
    IonLabel,
    IonToolbar,
    IonTitle,
    IonContent,
    CreatedBy
  },
  computed: {
    ...mapGetters(['secteurs'])  // TODO : Trier les secteurs par num ASC
  },
}
</script>

<style scoped lang="sass">

.mini-plan
  display: block
  padding: 20px
  max-width: 100%

ion-avatar
  color: var(--ion-color-primary-contrast)
  padding-top: 3px
  text-align: center
  font-weight: bold
  font-size: 40px
  height: 55px
  width: 55px
</style>
